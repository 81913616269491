
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
/**
 * @module Home
 * @description Home Page
 */
import Head from 'next/head';
import Tooltip from '@/blocks/Tooltip';

// Template
import Template from '@/templates/Default';

// Blocks
import ReturnVisitorModal from '@/blocks/Modals/ReturnVisitorModal';
import ResumeQuoteModal from '@/blocks/Modals/ResumeQuoteModal';
import HeroAddressCheck from '@/blocks/Heroes/HeroAddressCheck';
import ColumnContent from '@/blocks/ColumnContent';
import SplitSection from '@/blocks/SplitSection';
import SplitSectionGamers from '@/blocks/SplitSection/SplitSectionGamers';
import BannerCTA from '@/blocks/Banners/BannerCTA';
import { useContext } from 'react';
import { FuelContext } from '@/providers/fuel-provider';
import MonarchFallback from '@/blocks/MonarchFallback';

// Cohesion
import { MonarchSlot } from '@redventures/cohesion-utils-react';
/**
 * @function Page
 * @description Page
 */
const Page = () => {
  const { resumeQuote, phoneHeavy, quoteCreatedAfterCutoffDate } = useContext( FuelContext );

  return (
    <>
      <Head>
        <title>Frontier Internet</title>
        <meta name="description" content="Enjoy fiber internet, TV & phone services from Frontier. Explore the best Internet, TV, and phone packages and deals we offer. More digital solutions available." />
      </Head>

      <Template
        title="Frontier Internet"
        modal={
          ( ! quoteCreatedAfterCutoffDate && resumeQuote && ! phoneHeavy ) ? <ResumeQuoteModal /> : ( <MonarchSlot slotId="infrModal">
              <ReturnVisitorModal />
            </MonarchSlot> )
        }
        hero={
          <>
            { /* Home | Banner */ }
            <MonarchSlot slotId="infrHomeBanner"/>

            { /* Home | Hero */ }
            <MonarchSlot slotId="infrHomeHero" fallback={ <MonarchFallback height={ [ '515px', '325px', '430px' ] }/> }>
              <HeroAddressCheck
                showFormLead={ false }
                headline={ <>Fiber internet <span className="u-color-blue">at light speed</span></> }
                background="/migration/sections/hero/lady-tablet-night-cityscape"
              />
            </MonarchSlot>

            { /* Home | Below Hero */ }
            <MonarchSlot slotId="infrHomeBelowHero"/>
          </>
        }
        primary={
          <>
            { /* Home | Section 1 */ }
            <MonarchSlot slotId="infrHomeSection1">
              <ColumnContent
                headline="Get ready for an upgrade."
                subhead="Go further with Fiber 1 Gig Internet"
                items={ [
                  {
                    icon: 'padlock-unlocked',
                    headline: 'Unlock unlimited',
                    content: <>Stream, work and game with no data caps or overage charges.<Tooltip disclosure="data-caps" placement="right" /></>
                  },
                  {
                    icon: 'monitor',
                    headline: 'Expert installation',
                    content: <>We make setup easy with free expert installation available for every fiber order.</>
                  },
                  {
                    icon: 'wifi-v3',
                    headline: 'Connect it all',
                    content: <>Get an Amazon eero Pro 6 router included at no additional monthly cost.<span data-legal="eero" /></>
                  },
                  {
                    icon: 'cables',
                    headline: 'Go fiber fast',
                    content: <>Experience upload speeds 10x faster than cable.<span data-legal="Gig-25x-faster-than-cable" /></>
                  }
                ] }
              />
            </MonarchSlot>

            { /* Home | Section 2 */ }
            <MonarchSlot slotId="infrHomeSection2">
              <SplitSection
                title="Embrace internet that can"
                subtitle="Experience the power of a 100% fiber-optic network, with 99.9% network reliability and blazing-fast speeds."
                image={ {
                  url: '/migration/sections/split-section/man-and-child-using-laptop.jpg',
                  blurUrl: '/migration/sections/split-section/man-and-child-using-laptop.jpg',
                  alt: 'Man and child using laptop'
                } }
              />
            </MonarchSlot>

            { /* Home | Section 3 */ }
            <MonarchSlot slotId="infrHomeSection3">
              <SplitSection
                title="Do more even faster"
                subtitle="Fiber supercharges your internet so you can work, live and play the way you want."
                theme="dark"
                reverseLayout
                image={ {
                  url: '/INFR/sections/man-in-headphones-blue-tint.jpg',
                  blurUrl: '/INFR/sections/man-in-headphones-blue-tint.jpg',
                  alt: 'Man wearing headphones'
                } }
              />

              <SplitSectionGamers
                items={ [
                  '99.9% network reliability',
                  'Virtually eliminate lag with our high speed fiber network',
                  'Game, stream and video chat on multiple devices'
                ] }
              />
            </MonarchSlot>

            { /* Home | Section 4 */ }
            <MonarchSlot slotId="infrHomeSection4">
              <BannerCTA
                theme="blue"
                copy="Ready to find internet plans available in your neighborhood?"
              />
            </MonarchSlot>

            { /* Home | Section 5 */ }
            <MonarchSlot slotId="infrHomeSection5"/>

            { /* Home | Section 6 */ }
            <MonarchSlot slotId="infrHomeSection6"/>

            { /* Home | Section 7 */ }
            <MonarchSlot slotId="infrHomeSection7"/>

            { /* Home | Sticky CTA */ }
            <MonarchSlot slotId="infrHomeStickyCta"/>
          </>
        }>
      </Template>
    </>
  );
};

export default Page;
